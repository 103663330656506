import React from "react";

import { Box, Card, CardHeader, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useFormik } from "formik";

import * as Yup from "yup";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomerHealthInfo = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formik = useFormik({
    initialValues: 
        typeof props.customer.healthInfo != "undefined"
          ? {
              age: props.customer.healthInfo?.age || "",
              weight: props.customer.healthInfo?.weight || "",
              height: props.customer.healthInfo?.height || "",
              healthCondition: props.customer.healthInfo?.healthCondition || "",
            }
          : {
            healthInfo: {}
            },
  
    validationSchema: Yup.object({
      // firstName: Yup.string()
      //   .max(15, "Must Be 15 characters or less")
      //   .required("Required"),
      // lastName: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("Required"),
      // email: Yup.string().email("Invalid Email Address").required("Required"),
      // notes: Yup.string()
      //   .required("Required")
      //   .min(20, "Must be 20 characters or more"),
    }),

    enableReinitialize: true,

    onSubmit: (values) => {
      const updatedCustomer = props.customer;
      updatedCustomer.healthInfo = { ...props.customer.healthInfo, ...values };
      props.onChange(updatedCustomer);

      // const results ={healthInfo: formik.values}
      // props.onSubmit();
      // props.onAddLead(values);
      // props.onChange(results);
      // alert(JSON.stringify(results, null, 2));
    },
  });
  return (
    <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
      <Card elevation={0}>
        <CardHeader
          title={"Health Info"}
          subheader={
            typeof props.customer.healthInfo != "undefined"
              ? "Information on file"
              : "Not on File"
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <form onSubmit={formik.handleSubmit}> */}
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <div>
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="age"
                  name="age"
                  label="Age"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.age && Boolean(formik.errors.age)}
                  helperText={formik.touched.age && formik.errors.age}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="weight"
                  name="weight"
                  label="Weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="height"
                  name="height"
                  label="Height"
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}
                />
              </div>
              <div>
                <TextField
                  // variant="standard"
                  fullWidth
                  multiline
                  rows={3}
                  // size="small"
                  // margin="dense"
                  id="healthCondition"
                  name="healthCondition"
                  label="Health Condition"
                  value={formik.values.healthCondition}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.healthCondition &&
                    Boolean(formik.errors.healthCondition)
                  }
                  helperText={
                    formik.touched.healthCondition &&
                    formik.errors.healthCondition
                  }
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!(formik.dirty && formik.isValid)}
                >
                  Update
                </Button>
              </div>
            </Box>
            {/* </form> */}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default CustomerHealthInfo;
