import React, { lazy, Suspense, useState, useCallback, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./tools/http";

// Importing loaders
import { loader as projectLoader } from "./pages/Project";
import { loader as activeProjectsLoader } from "./pages/Operations";
import { loader as customerLoader } from "./pages/Customer";
import { loader as contactsLoader } from "./components/Project/NewProjectForm";

import { checkAuthLoader, tokenLoader } from "./tools/auth";
import { AuthContext } from "./store/auth-context";
import Auth from "./pages/Auth";

const Home = lazy(() => import("./pages/Home"));
const RootLayout = lazy(() => import("./pages/Root"));
const Intake = lazy(() => import("./pages/Intake"));
const Lead = lazy(() => import("./pages/Lead"));
const Testing = lazy(() => import("./pages/Testing"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Customer = lazy(() => import("./pages/Customer"));
const NewProjectForm = lazy(() =>
  import("./components/Project/NewProjectForm")
);
const CustomerToQb = lazy(() => import("./components/Customer/CustomerToQb"));
const NewNoteForm = lazy(() => import("./components/Notes/NewNoteForm"));
const Project = lazy(() => import("./pages/Project"));
const ExpenseTable = lazy(() =>
  import("./components/Finance/pages/ExpenseTable")
);
const Operations = lazy(() => import("./pages/Operations"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    loader: tokenLoader,
    children: [
      { index: true, element: <Home />, loader: checkAuthLoader },
      { path: "auth", element: <Auth /> },
      { path: "intake", element: <Intake />, loader: checkAuthLoader },
      { path: "lead/:id", element: <Lead />, loader: checkAuthLoader },
      { path: "contacts", element: <Contacts />, loader: checkAuthLoader },
      { path: "test", element: <Testing />, loader: checkAuthLoader },
      {
        path: "project/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Project />
          </Suspense>
        ),
        loader: projectLoader, //protected
        children: [
          {
            path: "finances",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <ExpenseTable />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "Operations",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Operations />
          </Suspense>
        ),
        loader: activeProjectsLoader, //protected
      },
      {
        path: "customer/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Customer />
          </Suspense>
        ),
        loader: customerLoader, //protected
        children: [
          {
            path: "newproject",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <NewProjectForm />
              </Suspense>
            ),
            loader: contactsLoader,
          },
          {
            path: "newnote",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <NewNoteForm />
              </Suspense>
            ),
          },
          {
            path: "quickbooks",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <CustomerToQb />
              </Suspense>
            ),
            loader: contactsLoader,
          },
        ],
      },
    ],
  },
]);

let logoutTimer;

function App() {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [email, setEmail] = useState(null);

  const login = useCallback((uid, token, email, expirationDate) => {
    setToken(token);
    setUserId(uid);
    setEmail(email);
    const tokenExpirationDate =
      // expirationDate || new Date(new Date().getTime() + 2000);
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
      setTokenExpirationDate(tokenExpirationDate)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        email: email,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setEmail(null);
    setTokenExpirationDate(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.email,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        login: login,
        logout: logout,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}

export default App;
