import ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Card, CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import { useQuery, useMutation } from "@tanstack/react-query";

import { updateFinance, fetchContacts, queryClient } from "../../tools/http";

const SideFormTemp = (props) => {
  const data = props.data;

  const navigate = useNavigate();



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "Projected Expense",
      category: "",
      amount: "",
      description: "",
      contact: "",
      msrp: 0,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Required"),

      amount: Yup.number()
        .required("Required")
        .positive("Must be a greater than zero")
        .typeError("Must be a number"),

      description: Yup.string().required("Required"),

      contact: Yup.string().required("Required"),
    }),

    onSubmit: (values) => {
    //   const update = {
    //     _id: data.finance._id,
    //     values: values,
    //   };
    //   mutateFinance(update);
      // console.log(update)
      console.log(values);
      // const newData={...data, ...values}
      // console.log(newData)
      // props.onChange(newData);
      // alert(JSON.stringify(values, null, 2));
      // props.onAddLead(values);
      formik.resetForm((values = ""));
      props.showSideForm(false);
    },
  });

//   if (contactsLoading) {
//     return;
//   }

  return (
    <>
      <Grid2 container spacing={1} xs={12}>
        <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
          {/* <Grid2 xs={12}> */}
          <Card elevation={4}>
            <CardHeader title={"Temporary Placeholder"} />
            <form onSubmit={formik.handleSubmit}>
             <Typography>this will be a form to enter data based on where you are in the process, ranging from 
              a review checklist to entering other info
             </Typography>

              <Button
                sx={{ borderRadius: "50px", m: 1 }}
                type="submit"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Enter Expense
              </Button>

              <Button
                // size="small"
                sx={{ borderRadius: "50px", m: 1 }}
                onClick={() => props.showSideForm(false)}
                // type="Enter"
                variant="contained"
                // disabled={!(formik.isValid && formik.dirty)}
              >
                Cancel
              </Button>
            </form>
          </Card>

          {/* </Grid2> */}
        </Box>
      </Grid2>
    </>
  );
};

export default SideFormTemp;


