import React from "react";

import { Box, Card, CardHeader, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useFormik } from "formik";

import * as Yup from "yup";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomerMobilityDevice = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formik = useFormik({
    initialValues: 
        typeof props.customer.mobilityDevice != "undefined"
          ? {
              device: props.customer.mobilityDevice?.device || "",
              make: props.customer.mobilityDevice?.make || "",
              model: props.customer.mobilityDevice?.model || "",
              serial: props.customer.mobilityDevice?.serial || "",
              weight: props.customer.mobilityDevice?.weight || "",
              notes: props.customer.mobilityDevice?.notes || "",
            }
          : {
            mobilityDevice: {}
            },
  
    validationSchema: Yup.object({
      // firstName: Yup.string()
      //   .max(15, "Must Be 15 characters or less")
      //   .required("Required"),
      // lastName: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("Required"),
      // email: Yup.string().email("Invalid Email Address").required("Required"),
      // notes: Yup.string()
      //   .required("Required")
      //   .min(20, "Must be 20 characters or more"),
    }),

    enableReinitialize: true,

    onSubmit: (values) => {

      const updatedCustomer = props.customer;
      updatedCustomer.mobilityDevice = { ...props.customer.mobilityDevice, ...values };
      props.onChange(updatedCustomer);

      
      // const results ={mobilityDevice: formik.values}
      // props.onSubmit();
      // props.onAddLead(values);
      // props.onUpdate(results);
      // alert(JSON.stringify(results, null, 2));
    },
  });
  return (
    <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
      <Card elevation={0}>
        <CardHeader
          title={"Mobility Device"}
          subheader={
            typeof props.customer.mobilityDevice != "undefined"
              ? "Information on file"
              : "Not on File"
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <form onSubmit={formik.handleSubmit}> */}
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <div>
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="device"
                  name="device"
                  label="Device"
                  value={formik.values.device}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.device && Boolean(formik.errors.device)}
                  helperText={formik.touched.device && formik.errors.device}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="make"
                  name="make"
                  label="Make"
                  value={formik.values.make}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.make && Boolean(formik.errors.make)}
                  helperText={formik.touched.make && formik.errors.make}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="model"
                  name="model"
                  label="Model"
                  value={formik.values.model}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.model && Boolean(formik.errors.model)}
                  helperText={formik.touched.model && formik.errors.model}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="serial"
                  name="serial"
                  label="Serial"
                  value={formik.values.serial}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.serial && Boolean(formik.errors.serial)}
                  helperText={formik.touched.serial && formik.errors.serial}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="weight"
                  name="weight"
                  label="Weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}
                />
</div>
              <div>
                <TextField
                  // variant="standard"
                  fullWidth
                  multiline
                  rows={3}
                  // size="small"
                  // margin="dense"
                  id="notes"
                  name="notes"
                  label="Notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.notes &&
                    Boolean(formik.errors.notes)
                  }
                  helperText={
                    formik.touched.notes &&
                    formik.errors.notes
                  }
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!(formik.dirty && formik.isValid)}
                >
                  Update
                </Button>
              </div>
            </Box>
            {/* </form> */}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default CustomerMobilityDevice;