import ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Card, CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";

import { useQuery } from "@tanstack/react-query";

import { fetchContacts } from "../../tools/http";

// import { useHttpClient } from "../../tools/hooks/http-hook";
import { projStatus } from "./ProjectStatusType";

const ProjectInfo = (props) => {
  const data = props.data;
  // console.log(data);
  //   const params = useParams();
  const navigate = useNavigate();
  //   const portalElement = document.getElementById("overlays");

  const { data: contacts, isLoading: contactsLoading } = useQuery({
    queryKey: ["contacts"],
    queryFn: ({ signal }) => fetchContacts({ signal }),
    staleTime: 10000,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // //   customer: params.id,
      projectType: data.projectType,
      equipment: data.equipment,
      description: data.description,
      projectStatus: data.projectStatus,
      fundingSource: contactsLoading
        ? ""
        : !data.fundingSource._id
        ? ""
        : data.fundingSource._id,
      fundingSourceContact: contactsLoading
        ? ""
        : !data.fundingSourceContact
        ? ""
        : data.fundingSourceContact,
      subContractor: contactsLoading
        ? ""
        : !data.subContractor
        ? ""
        : data.subContractor,
    },
    validationSchema: Yup.object({
      projectType: Yup.string().required("Required"),

      //   lastName: Yup.string()
      //     .max(20, "Must be 20 characters or less")
      //     .required("Required"),

      //   email: Yup.string().email("Invalid Email Address").required("Required"),

      //   notes: Yup.string()
      //     .required("Required")
      //     .min(20, "Must be 20 characters or more"),
    }),

    onSubmit: (values) => {
      // console.log(values);
        if(values.fundingSourceContact === ""){
          delete values.fundingSourceContact;
        }
        const newData={...data, ...values}
console.log(newData)
        props.onChange(newData);
        // alert(JSON.stringify(values, null, 2));
          // props.onAddLead(values);
        // formik.resetForm((values = ""));
    },
  });

  if (contactsLoading) {
    return;
  }
  
  return (
    <>
      <Grid2 container spacing={1} xs={12}>
        <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
          {/* <Grid2 xs={12}> */}
          <Card elevation={4}>
            <CardHeader title={"Project Info"} />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                select
                fullWidth
                size="small"
                margin="dense"
                id="projectType"
                name="projectType"
                label="Project Type"
                //   helperText="Select Project Type"
                value={formik.values.projectType}
                // defaultValue={data.projectType}
                onChange={formik.handleChange}
                // onChange={onChangeLookUp}
                // onBlur={onChangeLookUp}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.projectType &&
                  Boolean(formik.errors.projectType)
                }
                helperText={
                  formik.touched.projectType && formik.errors.projectType
                }
              >
                <MenuItem value="Product">Product</MenuItem>
                <MenuItem value="Rental">Rental</MenuItem>
                <MenuItem value="Home Mode">Home Mod</MenuItem>
              </TextField>

              <TextField
                select
                fullWidth
                size="small"
                margin="dense"
                id="equipment"
                name="equipment"
                label="Equipment"
                //   helperText="Select Project Type"
                value={formik.values.equipment}
                // defaultValue={data.equipment}
                onChange={formik.handleChange}
                // onChange={onChangeLookUp}
                // onBlur={onChangeLookUp}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.equipment && Boolean(formik.errors.equipment)
                }
                helperText={formik.touched.equipment && formik.errors.equipment}
              >
                <MenuItem value="Patient Lift - Ceiling">
                  Patient Lift - Ceiling
                </MenuItem>
                <MenuItem value="Roll-in-shower">Roll-in-shower</MenuItem>
                <MenuItem value="Ramps">Ramps</MenuItem>
                <MenuItem value="Stair Glides - Straight">
                  Stair Glides - Straight
                </MenuItem>
                <MenuItem value="Stair Glides - Curved">
                  Stair Glides - Curved
                </MenuItem>
                <MenuItem value="VPL">VPL</MenuItem>
                <MenuItem value="Patient Lift - Floor">
                  Patient Lift - Floor
                </MenuItem>
                <MenuItem value="Standard Shower">Standard Shower</MenuItem>
                <MenuItem value="Standard Tub">Standard Tub</MenuItem>
                <MenuItem value="Walk-in-tub">Walk-in-tub</MenuItem>
                <MenuItem value="DME">DME</MenuItem>
                <MenuItem value="Grab Bars">Grab Bars</MenuItem>
                <MenuItem value="IPL">IPL</MenuItem>
                <MenuItem value="Pool Lifts">Pool Lifts</MenuItem>
                <MenuItem value="Vehicle Lifts">Vehicle Lifts</MenuItem>
                <MenuItem value="HandRail">HandRail</MenuItem>
                <MenuItem value="Walker Steps">Walker Steps</MenuItem>
              </TextField>

              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                margin="dense"
                id="description"
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              ></TextField>

              <TextField
                fullWidth
                select
                size="small"
                margin="dense"
                id="projectStatus"
                name="projectStatus"
                label="Project Status"
                value={formik.values.projectStatus}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.projectStatus &&
                  Boolean(formik.errors.projStatus)
                }
                helperText={
                  formik.touched.projectStatus && formik.errors.projectStatus
                }
              >
                {projStatus.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                size="small"
                margin="dense"
                id="fundingSource"
                name="fundingSource"
                label="Funding Source"
                value={formik.values.fundingSource}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fundingSource &&
                  Boolean(formik.errors.fundingSource)
                }
                helperText={
                  formik.touched.fundingSource && formik.errors.fundingSource
                }
              >
                {contacts
                  .filter((option) => {
                    return option.contactType === "funding source";
                  })
                  .map((option) => (
                    <MenuItem key={option.id} value={option._id}>
                      {option.companyName}
                    </MenuItem>
                  ))}
              </TextField>

              <TextField
                fullWidth
                select
                size="small"
                margin="dense"
                id="fundingSourceContact"
                name="fundingSourceContact"
                label="Funding Source Contact"
                value={formik.values.fundingSourceContact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fundingSourceContact &&
                  Boolean(formik.errors.fundingSourceContact)
                }
                helperText={
                  formik.touched.fundingSourceContact &&
                  formik.errors.fundingSourceContact
                }
              >
                <MenuItem key={1} value=""></MenuItem>
                {
                  // contacts.filter((option) => {return (option._id === formik.values.fundingSource)}).map((option) => (
                  //   <MenuItem key={option.contactPoint[0]._id} value={option.contactPoint[0]._id}>{option.contactPoint[0].name}</MenuItem>
                  // ))
                  contacts[
                    contacts.findIndex(
                      (ind) => ind._id === formik.values.fundingSource
                    )
                  ]?.contactPoint.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))
                }
              </TextField>

              <TextField
                fullWidth
                select
                size="small"
                margin="dense"
                id="subContractor"
                name="subContractor"
                label="Sub Contractor"
                value={formik.values.subContractor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.subContractor &&
                  Boolean(formik.errors.subContractor)
                }
                helperText={
                  formik.touched.subContractor && formik.errors.subContractor
                }
              >
                {contacts
                  .filter((option) => {
                    return option.contactType === "sub contractor";
                  })
                  .map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.companyName}
                    </MenuItem>
                  ))}
              </TextField>
              <Button
                type="submit"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Update
              </Button>
            </form>
          </Card>

          {/* </Grid2> */}
        </Box>
      </Grid2>
    </>
  );
};

export default ProjectInfo;

// export async function loader() {
//   return queryClient.fetchQuery({
//     queryKey: ["contacts"],
//     queryFn: ({ signal }) => fetchContacts({ signal }),
//   });
// }
