import React, {useState, useContext} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
// import MenuItem from "@mui/material/MenuItem";

import { useQuery, useMutation } from "@tanstack/react-query";

import { login, queryClient } from "../tools/http";
import { AuthContext } from "../store/auth-context";

const Auth = () => {
  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
//   const portalElement = document.getElementById("overlays");

//   const { data: contacts } = useQuery({
//     queryKey: ["contacts"],
//     queryFn: ({ signal }) => fetchContacts({ signal }),
//     staleTime: 10000,
//   });

  const { mutate } = useMutation({
    mutationFn: login,

    onSuccess: (data) => {
      console.log(data)
      auth.login(data.uid, data.token, data.email)
      // console.log("project mutation");
      // queryClient.invalidateQueries({ queryKey: ["customer"] });
      navigate("/");
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password:  "",
    },
    validationSchema: Yup.object({
      // projectType: Yup.string().required("Required"),

      //   firstName: Yup.string()
      //     .max(15, "Must Be 15 characters or less")
      //     .required("Required"),

      email: Yup.string().email("Invalid Email Address").required("Required"),

    }),

    onSubmit: (values) => {
      // console.log(values);
      mutate(values);
      // console.log(resp)
      // alert(JSON.stringify(values, null, 2));

      // formik.resetForm((values = ""));
      // navigate("/");

    },
  });

  return (
    <>
        {/* // <Drawer anchor="right" open={true} variant="persistent"> */}
          <Grid2 container spacing={1} xs={6} mt={8} ml={3}>
            <Box sx={{ bgcolor: "background.paper", width: 400 }}>
              <Grid2 xs={12}>
                <form onSubmit={formik.handleSubmit}>

                  <TextField
                    fullWidth
                    size="small"
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.email)
                    }
                    helperText={
                      formik.touched.email && formik.errors.email
                    }
                  ></TextField>

                  <TextField
                  type="password"
                    fullWidth
                    size="small"
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password &&
                      Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  ></TextField>

                  <Button variant="contained" type="submit">
                    login
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate("../");
                    }}
                  >
                    Cancel
                  </Button>
                </form>
              </Grid2>
            </Box>
          </Grid2>

    </>
  );
};

export default Auth;

// export async function loader() {
//   return queryClient.fetchQuery({
//     queryKey: ["contacts"],
//     queryFn: ({ signal }) => fetchContacts({ signal }),
//   });
// }
