import React from "react";
// import { useLoaderData } from "react-router-dom";
import { Link, Outlet, useParams, redirect } from "react-router-dom";

import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchCustomer, queryClient, updateCustomer } from "../tools/http";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';

import ShortProjectTable from "../components/Project/ShortProjectTable";
import NotesTable from "../components/Notes/NotesTable";
import CustomerDetails from "../components/Customer/CustomerDetails";
import CustomerAltContacts from "../components/Customer/CustomerAltContacts";
import CustomerHealthInfo from "../components/Customer/CustomerHealthInfo";
import CustomerMobilityDevice from "../components/Customer/CustomerMobilityDevice";
import ActionMenu from "../components/Customer/ActionMenu";
import NewMenu from "../components/Customer/NewMenu";

import { getAuthToken } from "../tools/auth";

export default function Customer() {

  const params = useParams();

  const { data } = useQuery({
    queryKey: ["customer"],
    queryFn: ({ signal }) => fetchCustomer({ signal, id: params.id }),
    staleTime: 10000,
  });
  // console.log(data);

  const {mutate: mutateCustomer} = useMutation({
    mutationFn: updateCustomer,
      onSuccess: () => {
      console.log('mutate success');
      queryClient.invalidateQueries({queryKey: ['customer']});
    }
  })

const customerChangeHandler = async (updatedCustomer) => {
 const response = await mutateCustomer(updatedCustomer);
}

  return (
    <Grid2 spacing={2} p={2} container>
      <Outlet />

      {/* <Grid2 xs={12}>


      </Grid2> */}

      <Grid2 xs={12}>
        <Typography
          variant={"h4"}
          color={"primary"}
        >{`${data.customerType}:  ${data.info.firstName} ${data.info.lastName}`}</Typography>
                <Stack spacing={1} direction="row">
          {/* this will need modification to work with the routing function */}
          {/* <Button variant="contained" size="small" href={`/newproject`}>New Project</Button> */}
        {/* <Link to={`/customer/${data._id}/newproject`}>New Project</Link> */}
        {/* <Link to={`/customer/${data._id}/newnote`}>New Note</Link> */}
        {/* <Link to={`/customer/${data._id}/quickbooks`}>Send to quickbooks</Link> */}
        {/* <Link to={`/lead/${data._id}`}>Quick Quote</Link> */}
        <NewMenu data={data} />
        {data.customerType == "lead" ? (<ActionMenu data={data} />) : (null)}
        
        </Stack>
      </Grid2>

      <Grid2 xs={6}>
        <CustomerDetails customer={data} onChange={customerChangeHandler} />
        <CustomerAltContacts customer={data} onChange={customerChangeHandler}/>
        <CustomerHealthInfo customer={data} onChange={customerChangeHandler}/>
        <CustomerMobilityDevice customer={data} onChange={customerChangeHandler}/>

      </Grid2>

      <Grid2 xs={6}>
        <ShortProjectTable projects={data.projects} />
        <NotesTable notes={data.notes} />
      </Grid2>
    </Grid2>
  );
}

// export default Customer;

export async function loader({ params }) {
  
  const info = getAuthToken();
  
  if (!info) {
      console.log("no")
    return redirect('/auth');
    // return null;
  }else 
  return queryClient.fetchQuery({
    queryKey: ["customer"],
    queryFn: ({ signal }) => fetchCustomer({ signal, id: params.id }),
    staleTime: 10000,
  });
}
