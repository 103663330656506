import React, {useState} from "react";
import { redirect } from 'react-router-dom';
import { Link, useParams, Outlet } from "react-router-dom";

import { useQuery, useMutation } from "@tanstack/react-query";

import {
  fetchProject,
  queryClient,
  updateProject,
  newNote,
} from "../tools/http";
import { getAuthToken } from "../tools/auth";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Typography } from "@mui/material";

import NotesTable from "../components/Notes/NotesTable";
// import EvalActions from "../components/Project/EvalActions";
import ProjectInfo from "../components/Project/ProjectInfo";
import ProjectTimeline from "../components/Project/ProjectTimeline";
import ExpenseForm from "../components/Project/ExpenseForm";
import QuoteAmount from "../components/Project/QuoteAmount";
import SideFormTemp from "../components/Project/SideFormTemp";
import LayoutReview from "../components/Project/LayoutReview";
// import Chat from "../components/Project/Chat";

function Project() {
   
  const [showSideForm, setShowSideForm] =useState(false)

  const { mutate: mutateProject } = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      console.log("mutate success");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const { mutate: addNote } = useMutation({
    mutationFn: newNote,
    onSuccess: () => {
      // console.log("project mutation");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  // const {mutate: newlayout} = useMutation({
  //   mutationFn: newLayoutJob,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({queryKey: ["project"]})
  //   }
  // });

  const params = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["project"],
    queryFn: ({ signal }) => fetchProject({ signal, id: params.id }),
    // staleTime: 10000,
  });

  const acceptProjectHandler = async (event) => {
    const newData = { ...data, evaluation: {} };
    newData.statusFlags.isAccepted = true;
    newData.evaluation.dateAccepted = new Date();
    // console.log(newData);
    await addNote({
      data: {
        enteredBy: "Eric - automated",
        date: new Date(),
        // description: "a new project accepted",
        description: `Accepted ${newData.equipment} ${newData.projectType} project . Description: ${newData.description}`,
      },
      id: data.customer._id,
    });
    await mutateProject(newData);
  };

  const update = async (event) => {
    await mutateProject(event);
  };

  if (isLoading) {
    return;
  }
  return (
    <Grid2 spacing={2} p={2} container>
{/* <Outlet></Outlet> */}
      <Grid2 xs={12}>
        <Typography variant={"h4"} color={"primary"}>
          {`Project:  ${data.description}`}
        </Typography>

        <Link to={`/customer/${data.customer._id}`}>
          <Typography variant={"h6"} color={"primary"}>
            {`Customer:  ${data.customer.info.firstName} ${data.customer.info.lastName}`}
          </Typography>
        </Link>
      </Grid2>
<Grid2 xs={12}>
<Outlet />
</Grid2>
      <Grid2 xs={12}>
        {!data.statusFlags.isAccepted ? (
          <Button
            variant="contained"
            size="small"
            onClick={acceptProjectHandler}
          >
            Accept Project
          </Button>
        ) : (
          <>
            <Grid2 container>
              <Grid2 xs={3}>
                <ProjectInfo data={data} onChange={update} />
              </Grid2>

              <Grid2 xs={6}>
                <ProjectTimeline project={data} onChange={update} showSideForm={setShowSideForm} />
              </Grid2>

              <Grid2 xs={3}>
                {(showSideForm==="quote") ? <QuoteAmount data={data} showSideForm={setShowSideForm}/> : ""}
                {(showSideForm==="expense") ? <ExpenseForm data={data} showSideForm={setShowSideForm}/> : ""}
                {(showSideForm==="temp") ? <SideFormTemp data={data} showSideForm={setShowSideForm}/> : ""}
                {(showSideForm==="layoutReview") ? <LayoutReview data={data} showSideForm={setShowSideForm}/> : ""}
                
                
                {/* <Chat></Chat> */}
                {/* {isLoading ? <h1>Loading</h1> : <ProjectInfo data={data} />} */}
              </Grid2>
            </Grid2>
          </>
        )}
      </Grid2>
      <NotesTable notes={data.customer.notes} />
    </Grid2>
  );
}

export default Project;

export async function loader({ params }) {
  const info = getAuthToken();
  
  if (!info) {
      console.log("no")
    return redirect('/auth');
    // return null;
  }else 
  return queryClient.fetchQuery({
    queryKey: ["project"],
    queryFn: ({ signal }) => fetchProject({ signal, id: params.id }),
  });
}
