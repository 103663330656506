import React from "react";

import { Box, Card, CardHeader, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useNavigate} from "react-router-dom";
// import { useQuery, useMutation } from "@tanstack/react-query";
// import { updateCustomer, queryClient } from "../../tools/http";

import { useFormik } from "formik";
//   import * as Yup from "yup";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomerDetails = (props) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formik = useFormik({
    initialValues:
      typeof props.customer.info != "undefined"
        ? {
            firstName: props.customer.info.firstName,
            middleName: props.customer.info?.middleName || "",
            lastName: props.customer.info.lastName,

            location: {
              address: props.customer.info.location.address,
              address2: props.customer.info.location.address2,
              city: props.customer.info.location.city,
              state: props.customer.info.location.state,
              zip: props.customer.info.location.zip,
            },

            contactInfo: {
              email: props.customer.info.contactInfo?.email || "",
              phone: props.customer.info.contactInfo?.phone || "",
              cell: props.customer.info.contactInfo?.cell || "",
            },
          }
        : { location: {}, contactInfo: {} },

    enableReinitialize: true,

    // validationSchema: Yup.object({
    //   firstName: Yup.string()
    //     .max(15, "Must Be 15 characters or less")
    //     .required("Required"),

    //   lastName: Yup.string()
    //     .max(20, "Must be 20 characters or less")
    //     .required("Required"),

    //   email: Yup.string().email("Invalid Email Address").required("Required"),
    // }),

    onSubmit: (values) => {
      const updatedCustomer = props.customer;
      updatedCustomer.info = { ...props.customer.info, ...values };
      props.onChange(updatedCustomer);
    },
  });

  return (
    <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
      <Card elevation={0}>
        <CardHeader
          title={`${props.customer.customerType} Details`}
          subheader={`${props.customer.info.firstName} ${props.customer.info.lastName}`}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
            >
              {/* <form onSubmit={formik.handleSubmit}> */}
              <div>
                <TextField
                  variant="standard"
                  size="small"
                  padding="1"
                  margin="dense"
                  id="firstName"
                  name="firstName"
                  label={<Typography>First Name</Typography>}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="middleName"
                  name="middleName"
                  label="Middle Name"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.middleName &&
                    Boolean(formik.errors.middleName)
                  }
                  helperText={
                    formik.touched.middleName && formik.errors.middleName
                  }
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
              <div>
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="address"
                  name="location.address"
                  label="Address"
                  value={formik.values.location.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="address2"
                  name="location.address2"
                  label="Address 2"
                  value={formik.values.location.address2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address2 && Boolean(formik.errors.address2)
                  }
                  helperText={formik.touched.address2 && formik.errors.address2}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="city"
                  name="location.city"
                  label="City"
                  value={formik.values.location.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="state"
                  name="location.state"
                  label="State"
                  value={formik.values.location.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="zip"
                  name="location.zip"
                  label="Zip"
                  value={formik.values.location.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </div>

              <div>
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="email"
                  name="contactInfo.email"
                  label="Email"
                  value={formik.values.contactInfo.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="phone"
                  name="contactInfo.phone"
                  label="Home Phone"
                  value={formik.values.contactInfo.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="cell"
                  name="contactInfo.cell"
                  label="Cell Phone"
                  value={formik.values.contactInfo.cell}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cell && Boolean(formik.errors.cell)}
                  helperText={formik.touched.cell && formik.errors.cell}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={!(formik.dirty && formik.isValid)}
                >
                  Update
                </Button>
              </div>
              {/* </form> */}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default CustomerDetails;
