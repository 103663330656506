import React from "react";
import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@tanstack/react-query";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  queryClient,
  updateProject,
} from "../../tools/http";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";

function EvalStatus(props) {

    const { mutate: mutateProject } = useMutation({
        mutationFn: updateProject,
        onSuccess: () => {
          console.log("mutate success");
          queryClient.invalidateQueries({ queryKey: ["project"] });
        },
      });

      const skipEval = async () => {
        const newData = {
          ...props.project,
          projectStatus: "Layout",
          evaluation: {
            ...props.project.evaluation,
            isComplete: true,
            isSkipped: true,
          },
        };
        // props.setValue("2");
        await mutateProject(newData);
      };

  return (
    <Grid2 container spacing={2} xs={12}>
    <Grid2 xs={9}>
      <Typography>
        {`Date Accepted:  ${
          props.project.evaluation?.dateAccepted
            ? new Date(
                props.project.evaluation?.dateAccepted
              ).toDateString()
            : "Not awarded"
        }`}
      </Typography>
      <Typography mt={1}>Sent to Logistics:</Typography>
      <Typography mt={1}>Logistics Accepted:</Typography>
      <Typography mt={1}>Appt Scheduled:</Typography>
      <Typography mt={1}>Appt Date:</Typography>
      <Typography mt={1}>Paperwork sent:</Typography>
      <Typography mt={1}>Paperwork Reviewed:</Typography>
    </Grid2>
    <Grid2 xs={3}>
      <Stack spacing={1}>
        {/* {props.project.evaluation?.isSkipped ? (
          ""
        ) : (
          <> */}
            <Button
              disabled={(props.project.evaluation?.isSkipped)}
              variant="contained"
              size="small"
              sx={{ borderRadius: "50px", mr: 1 }}
              onClick={skipEval}
            >
              Skip Eval
            </Button>

            <Button
            disabled={(props.project.evaluation?.isSkipped)}
              variant="contained"
              size="small"
              sx={{ borderRadius: "50px", mr: 1 }}
            >
              Send to Logistics
            </Button>

            <Button
            disabled
              variant="contained"
              size="small"
              sx={{ borderRadius: "50px", mr: 1 }}
            >
              Review
            </Button>
          {/* </>
        )} */}
      </Stack>
    </Grid2>
  </Grid2>
  );
}

export default EvalStatus;