import React from "react";

import { Box, Card, CardHeader, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useFormik } from "formik";
import * as Yup from "yup";

import CustomerAltContactsTable from "./CustomerAltContactsTable";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomerAltContacts = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      relation: "",
      email: "",
      phone: "",
      cell: "",
    },
    validationSchema: Yup.object({
      // firstName: Yup.string()
      //   .max(15, "Must Be 15 characters or less")
      //   .required("Required"),
      // lastName: Yup.string()
      //   .max(20, "Must be 20 characters or less")
      //   .required("Required"),
      // email: Yup.string().email("Invalid Email Address").required("Required"),
      // notes: Yup.string()
      //   .required("Required")
      //   .min(20, "Must be 20 characters or more"),
    }),
    onSubmit: (values, { resetForm }) => {
      const updatedCustomer = props.customer;
      updatedCustomer.altContact.push(values);
      props.onChange(updatedCustomer);
      resetForm();
    },
  });

  return (
    <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
      <Card elevation={0}>
        <CardHeader
          title={"Alt Contact"}
          subheader={`Not on File`}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <CustomerAltContactsTable customer={props.customer.altContact} />
            {/* <AltContactTable2 customer={props.customer}/> */}
            {/* <AltContactTable lead={props.lead} /> */}
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                "& .MuiTextField-root": { m: 1, width: "18ch" },
              }}
            >
              <div>
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="relation"
                  name="relation"
                  label="Relationship"
                  value={formik.values.relation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.relation && Boolean(formik.errors.relation)
                  }
                  helperText={formik.touched.relation && formik.errors.relation}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="phone"
                  name="phone"
                  label="Phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="cell"
                  name="cell"
                  label="Cell"
                  value={formik.values.cell}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cell && Boolean(formik.errors.cell)}
                  helperText={formik.touched.cell && formik.errors.cell}
                />
                <TextField
                  variant="standard"
                  size="small"
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!(formik.dirty && formik.isValid)}
                >
                  New Contact
                </Button>
              </div>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default CustomerAltContacts;
