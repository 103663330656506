import React from "react";
import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@tanstack/react-query";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  fetchProject,
  queryClient,
  updateProject,
  newNote,
  newLayoutJob,
} from "../../tools/http";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import LayoutReview from "./LayoutReview";

function LayoutStatus(props) {

  const { mutate: newlayout } = useMutation({
    mutationFn: newLayoutJob,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const { mutate: mutateProject } = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      console.log("mutate success");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

    const handleLayout = async () => {
    await newlayout(props.project);
  };

  const skipLayout = async () => {
    const newData = {
      ...props.project,
      projectStatus: "Estimate",
      layout: {
        ...props.project.layout,
        completed: true,
        isSkipped: true,
        reviewDate: new Date(),
      },
    };
    await mutateProject(newData);
  };

  return (
    <Grid2 container spacing={1} xs={12}>

      <Grid2 xs={9}>
        <Typography mt={1}>
          Sent to Layout:{" "}
          {props.project.layout?.sent &&
            new Date(props.project.layout.sent).toDateString()}
        </Typography>
        <Typography mt={1}>
          Layout Accepted:{" "}
          {props.project.layout?.accepted &&
            new Date(props.project.layout.accepted).toDateString()}
        </Typography>
        <Typography mt={1}>
          Layout by: {props.project.layout?.layoutBy}
        </Typography>
        <Typography mt={1}>
          Layout Status: {props.project.layout?.status}
        </Typography>
        <Typography mt={1}>
          Layout sent:{" "}
          {props.project.layout?.completedDate &&
            new Date(props.project.layout.completedDate).toDateString()}
        </Typography>
        <Typography mt={1}>
          Layout Reviewed:{" "}
          {props.project.layout?.reviewDate &&
            new Date(props.project.layout.reviewDate).toDateString()}
        </Typography>
      </Grid2>

      <Grid2 xs={3}>
        <Stack spacing={1}>
        <Button
        onClick={skipLayout}
            disabled={(!props.project.evaluation?.isComplete || props.project.layout?.reviewDate || props.project.layout?.sent)
              
            }
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
            // onClick={handleLayout}
          >
           Skip Layout
          </Button>

          <Button
            disabled={
              (
                !props.project.evaluation?.isComplete ||
                props.project.layout?.sent || props.project.layout?.reviewDate
              )
            }
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
            onClick={handleLayout}
          >
            Send to Layout
          </Button>

          <Button
            onClick={() => props.showSideForm("layoutReview")}
            disabled={!(props.project.layout?.completed && !props.project.layout?.reviewDate )}
            // disabled={(!props.project.evaluation?.isComplete || props.project.layout?.reviewDate )}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
            // onClick ={handleLayout}
          >
            Review
          </Button>
          {/* )} */}
        </Stack>
      </Grid2>

    </Grid2>
  );
}

export default LayoutStatus;
