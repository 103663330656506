import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import { useNavigate } from "react-router-dom";
// import DateFormat from "../../tools/DateFormat";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Card, CardHeader, Typography } from "@mui/material";
export default function notesTable(props) {
  const notes = props.notes;
  //   const navigate = useNavigate();
  // : GridColDef[]

  const columns = [
    { field: "date", headerName: "Date", width: 140 },
    { field: "description", headerName: "Note", minWidth: 350 },
    { field: "enteredBy", headerName: "User", width: 160 },
  ];

  const rows = notes.map((item) => {
    return {
      ...item,
      date: new Date(item.date).toDateString(),
    };
  });

  return (
    <Grid2 xs={12}>
      <Box borderRadius={"15px"} boxShadow={4} padding={0}>
        <Card>
          <CardHeader
            title={
              <Typography variant={"h5"} color={"primary"}>
                Notes
              </Typography>
            }
          />
          <Typography></Typography>
          <DataGrid
            getRowId={(row) => row._id}
            density="compact"
            // onRowClick={(event) => navigate(`/lead/${event.id}`)}
            rows={rows}
            columns={columns}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 200}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </Card>
      </Box>
    </Grid2>
  );
}
