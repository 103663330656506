import React from "react";
import { Link, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@tanstack/react-query";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  fetchProject,
  queryClient,
  newQuote,
  updateProject,
  newNote,
  newLayoutJob,
} from "../../tools/http";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import ExpenseTable from "./ExpenseTable";

function QuoteStatus(props) {
  const { mutate: thing } = useMutation({
    mutationFn: newQuote,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const handleQuote = async () => {
    await thing(props.project);
  };
  const params = useParams();
  return (
    <Grid2 container spacing={2} xs={12}>
      <Grid2 xs={9}>
        <Typography mt={1}>
          Estimate Started:{" "}
          {props.project.finance?.quoteStarted &&
            new Date(props.project.finance.quoteStarted).toDateString()}
        </Typography>

        <Typography mt={1}>Estimate Sent:</Typography>
        <Typography mt={1}>
          Estimate Amount: ${props.project.finance?.quoteAmount}
          {/* Estimate Amount: ${props.project.finance?.quoteAmount} */}
        </Typography>
        <Typography mt={1}>
          Total Projected Sale Price: ${props.project.finance?.totalSalePrice}
        </Typography>
        <Typography mt={1}>
          Total Projected Expense: ${props.project.finance?.totalExpense}
        </Typography>

        {/* <Typography mt={1}>
          Total MSRP: ${props.project.finance?.totalMsrp}
        </Typography> */}
        <Typography mt={1}>
          Projected Profit: ${props.project.finance?.projectedProfit}
        </Typography>

        <Typography mt={1}>
          Projected Profit Margin:{" "}
          {props.project.finance?.projectedProfitPercent}%
        </Typography>
      </Grid2>
      <Grid2 xs={3}>
        <Stack spacing={1}>
          {/* {props.project.finance?.quoteStarted ? ( */}
          {/* <> */}
          <Button
            disabled={
              !!(props.project.finance?.quoteStarted ||
              !props.project.layout?.reviewDate)
            }
            onClick={handleQuote}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
          >
            Start Quote
          </Button>

          <Button
            component={Link}
            // to="/finances"
            to={`/project/${params.id}/finances`}
            disabled={!props.project.finance?.quoteStarted}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
          >
            Enter Expense
          </Button>

          {/* <Button
            disabled={!props.project.finance?.quoteStarted}
            onClick={() => props.showSideForm("expense")}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
          >
            Enter Expense
          </Button> */}

          {/* <Button
            onClick={() => props.showSideForm("quote")}
            disabled={!(props.project?.finance?.items.length > 0)}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
          >
            Estimate Amount
          </Button> */}

          <Button
            disabled
            // onClick={handleQuote}
            variant="contained"
            size="small"
            sx={{ borderRadius: "50px", mr: 1 }}
          >
            Send Estimate
          </Button>
        </Stack>
      </Grid2>
      {/* {props.project.finance?.items.length > 0 ? (
        <ExpenseTable project={props.project} />
      ) : null} */}
    </Grid2>
  );
}

export default QuoteStatus;
