import ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Card, CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
// import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import { useQuery, useMutation } from "@tanstack/react-query";

import { updateFinance, queryClient } from "../../tools/http";

const QuoteAmount = (props) => {
  const data = props.data;
  // console.log(data);
  //   const params = useParams();
  //   const navigate = useNavigate();
  //   const portalElement = document.getElementById("overlays");

  const { mutate: mutateFinance } = useMutation({
    mutationFn: updateFinance,
    onSuccess: () => {
      console.log("mutatejob success");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: data.finance.quoteAmount,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Required")
        .positive("Must be a greater than zero")
        .typeError("Must be a number"),
    }),

    onSubmit: (values) => {
      const update = {
        _id: data.finance._id,
        values: values,
      };
      //   mutateFinance(update);
      // console.log(update)
      // console.log(values);
      // const newData={...data, ...values}
      // console.log(newData)
      // props.onChange(newData);
      // alert(JSON.stringify(values, null, 2));
      // props.onAddLead(values);
      formik.resetForm((values = ""));
      props.showSideForm(false);
    },
  });

  return (
    <Grid2 container spacing={1} xs={12}>
      <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
        {/* <Grid2 xs={12}> */}
        <Card elevation={4}>
          <CardHeader title={"Estimate Amount"} />
          <form onSubmit={formik.handleSubmit}>
            <Box padding={2}>
              <Typography mt={0}>
                Projected Expense: ${data.finance?.totalExpense}
              </Typography>
              <Typography mt={1}>
                Total MSRP: ${data.finance?.totalMsrp}
              </Typography>
              <Typography mt={1}>
                {/* Projected Profit: ${data.finance?.projectedProfit} */}
              </Typography>
              <Typography mt={1}>
                For a 50% markup: $
                {0.5 * data.finance?.totalExpense + data.finance.totalExpense}
              </Typography>

              <TextField
                fullWidth
                // multiline
                // rows={3}
                size="small"
                margin="dense"
                id="amount"
                name="amount"
                label="Estimate Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
              ></TextField>

              <Typography mt={1}>
                Profit: ${formik.values.amount - data.finance?.totalExpense}
              </Typography>
              <Typography mt={1}>
                Markup:
                {((formik.values.amount - data.finance?.totalExpense) /
                  data.finance?.totalExpense) *
                  100}
                %
              </Typography>
              <Typography mt={1}>
                GPM:
                {formik.values.amount
                  ? (
                      ((formik.values.amount - data.finance?.totalExpense) /
                        formik.values.amount) *
                      100
                    ).toFixed(0)
                  : 0}
                %
              </Typography>
              <Typography mt={1}>
                Discount from MSRP:
                {(
                  ((data.finance?.totalMsrp - formik.values.amount) /
                    data.finance?.totalMsrp) *
                  100
                ).toFixed()}
                %
              </Typography>

              <Button
                sx={{ borderRadius: "50px", m: 1 }}
                type="submit"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Save
              </Button>

              <Button
                sx={{ borderRadius: "50px", m: 1 }}
                onClick={() => props.showSideForm(false)}
                // type="Enter"
                variant="contained"
                // disabled={!(formik.isValid && formik.dirty)}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Card>

        {/* </Grid2> */}
      </Box>
    </Grid2>
  );
};

export default QuoteAmount;

// export async function loader() {
//   return queryClient.fetchQuery({
//     queryKey: ["contacts"],
//     queryFn: ({ signal }) => fetchContacts({ signal }),
//   });
// }
