import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import EvalStatus from "./EvalStatus";
import LayoutStatus from "./LayoutStatus";
import QuoteStatus from "./QuoteStatus";
import MaterialStatus from "./MaterialStatus";

export default function ProjectTimeline(props) {

  const [value, setValue] = useState("1");

  useEffect(() => {
    setValue(
      () => {
      if (!props.project.evaluation?.isComplete) return("1");
      if (!props.project.layout?.reviewDate) return ("2");
      if(!props.project.award?.awardedDate) return("3");
      if(!props.project.materials?.complete) return("4");

    }
    );
  }, [props.project,setValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClearUpdate = async () => {
    const newData = { ...props.project, update: "" };
    await props.onChange(newData);
  };

  const handleAward = async () => {
    const newData = {
      ...props.project,
      award: {
        awardedDate: new Date(),
      },
      projectStatus: "Approved",
    };
    setValue("4");
    await props.onChange(newData);
    console.log(newData);
  };

  // const skipEval = async () => {
  //   const newData = {
  //     ...props.project,
  //     projectStatus: "Layout",
  //     evaluation: {
  //       ...props.project.evaluation,
  //       isComplete: true,
  //       isSkipped: true,
  //     },
  //   };
  //   setValue("2");
  //   await props.onChange(newData);
  // };

  // const handleLayout = async () => {
  //   await props.onCreateLayout(props.project);
  // };

  return (
    <Grid2 xs={12}>
      <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
        <Card>
          <CardHeader
            title={
              <>
                <Typography variant={"h4"} color={"primary"}>
                  Timeline
                </Typography>
                {props.project.update ? (
                  <Typography>Latest Update: {props.project.update}</Typography>
                ) : null}

                <Button
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: "50px", mr: 1 }}
                >
                  Cancel
                </Button>

                {/* {props.project.update ?  */}
                <Button
                  disabled={!props.project.update}
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: "50px", mr: 1 }}
                  onClick={handleClearUpdate}
                >
                  Clear update
                </Button>
                {/* : null } */}

                <Button
                  disabled={props.project.award?.awardedDate}
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: "50px", mr: 1 }}
                  onClick={handleAward}
                >
                  Award
                </Button>
              </>
            }
          />
          <CardContent>
            {/* <Box sx={{ width: "100%", typography: "body1" }}> */}
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="Evaluation" value="1" />
                  <Tab label="Layout" value="2" />
                  <Tab label="Estimate" value="3" />
                  <Tab label="Materials" value="4" />
                  <Tab label="Assembly" value="5" />
                  <Tab label="Finances" value="6" />
                  <Tab label="Closeout" value="7" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <EvalStatus project={props.project} />
              </TabPanel>

              <TabPanel value="2">
                <LayoutStatus
                  project={props.project}
                  showSideForm={props.showSideForm}
                />
              </TabPanel>

              <TabPanel value="3">
                <QuoteStatus
                  project={props.project}
                  showSideForm={props.showSideForm}
                />
              </TabPanel>

              <TabPanel value="4">
                <MaterialStatus
                  project={props.project}
                  showSideForm={props.showSideForm}
                />
              </TabPanel>

              <TabPanel value="5">Assembly</TabPanel>

              <TabPanel value="6">Finances</TabPanel>
              <TabPanel value="7">Closeout</TabPanel>
            </TabContext>
            {/* </Box> */}
          </CardContent>
        </Card>
      </Box>
    </Grid2>
  );
}
