import { redirect } from 'react-router-dom';

export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem('expiration');
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
}

export function getAuthToken() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return userData;
  }

  export function tokenLoader() {
    const token = getAuthToken();
    return token;
  } 

  export function checkAuthLoader() {
   console.log('in authloader')
    const info = getAuthToken();
  
    if (!info) {
        console.log("no")
      return redirect('/auth');
      // return null;
    }
    return null
    
  }