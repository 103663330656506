import React from "react";
import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@tanstack/react-query";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { queryClient, updateProject } from "../../tools/http";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";

function MaterialStatus(props) {

  const { mutate: mutateProject } = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      console.log("mutate success");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const handleMaterialOrdered = async () => {
    const newData = {
      ...props.project,
      materials: {
        ordered: new Date(),
      }
    };
    // setValue("4");
    // await props.onChange(newData);
    mutateProject(newData)
  };

  return (
    <Grid2 container spacing={2} xs={12}>
      <Grid2 xs={9}>
        <Typography>
          {`Date awarded:  ${
            props.project.award?.awardedDate
              ? new Date(props.project.award?.awardedDate).toDateString()
              : "Not awarded"
          }`}
        </Typography>
        <Typography>Materials Ordered: {props.project.materials?.ordered &&
            new Date(props.project.materials.ordered).toDateString()}</Typography>
        <Typography >List of (multiple) Shipments and updates will go here, entered from shipping info button.  
          When shipping info is entered it will be transfered to logistics automatically</Typography>
      </Grid2>
      <Grid2 xs={3}>
      <Stack spacing={1}>
        <Button
        onClick={handleMaterialOrdered}
          disabled={!(props.project.award?.awardedDate && !props.project.materials?.ordered)}
          variant="contained"
          size="small"
          sx={{ borderRadius: "50px", mr: 1 }}
        >
          Materials Ordered
        </Button>

        <Button
        onClick={()=>props.showSideForm("temp")}
          disabled={!(props.project.materials?.ordered)}
          variant="contained"
          size="small"
          sx={{ borderRadius: "50px", mr: 1 }}
        >
          Shipping Info
        </Button>

        <Button
          disabled
          variant="contained"
          size="small"
          sx={{ borderRadius: "50px", mr: 1 }}
        >
          Review
        </Button>

        </Stack>
      </Grid2>
    </Grid2>
  );
}

export default MaterialStatus;
