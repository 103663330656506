import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Card, CardHeader, Typography } from "@mui/material";

export default function ShortProjectTable(props) {
  const projects = props.projects;
  const navigate = useNavigate();
  // : GridColDef[]

  const columns = [
    { field: "projectType", headerName: "Project Type", width: 180 },
    { field: "equipment", headerName: "Equipment", width: 200 },
    { field: "projectStatus", headerName: "Status", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
  ];

  const rows = projects;
  return (
    <Grid2 xs={12}>
      <Box borderRadius={"15px"} boxShadow={4} padding={1}>
        <Card>
          <CardHeader
            title={
              <Typography variant={"h5"} color={"primary"}>
                Projects
              </Typography>
            }
          />
          <Typography></Typography>
          <DataGrid
            getRowId={(row) => row._id}
            density="compact"
            onRowClick={(event) => navigate(`/project/${event.id}`)}
            rows={rows}
            columns={columns}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 200}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </Card>
      </Box>
    </Grid2>
  );
}
