// import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Card, CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";

import { useQuery, useMutation } from "@tanstack/react-query";
import { updateFinance, fetchContacts, queryClient } from "../../tools/http";

const ExpenseForm = (props) => {
  const [salesPrice, setSalesPrice] = useState(0);
  const [profit, setProfit] = useState(0);

  const data = props.data;
  // console.log(data);
  //   const params = useParams();
  const navigate = useNavigate();

  const { mutate: mutateFinance } = useMutation({
    mutationFn: updateFinance,
    onSuccess: () => {
      console.log("mutatejob success");
      queryClient.invalidateQueries({ queryKey: ["project"] });
    },
  });

  const { data: contacts, isLoading: contactsLoading } = useQuery({
    queryKey: ["contacts"],
    queryFn: ({ signal }) => fetchContacts({ signal }),
    staleTime: 10000,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "Projected Expense",
      category: "",
      amount: 0,
      description: "",
      contact: "",
      msrp: 0,
      markup: 0,
      SalePrice: 0,
      discount: 0,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Required"),

      amount: Yup.number()
        .required("Required")
        .positive("Must be a greater than zero")
        .typeError("Must be a number"),

      description: Yup.string().required("Required"),

      contact: Yup.string().required("Required"),
    }),

    onSubmit: (values) => {
      values.salePrice = values.msrp;
      const update = {
        _id: data.finance._id,
        values: {
          ...values,
          msrp: values.msrp ? values.msrp : values.amount*(1 + values.markup) / 100,
          salePrice: salesPrice,
        },
      };
      mutateFinance(update);
      // console.log(update)
      // console.log(values);
      // const newData={...data, ...values}
      // console.log(newData)
      // props.onChange(newData);
      // alert(JSON.stringify(values, null, 2));
      // props.onAddLead(values);
      formik.resetForm((values = ""));
      props.showSideForm(false);
    },
  });

  useEffect(() => {
    setSalesPrice(
      formik.values.msrp
        ? formik.values.msrp -
            (formik.values.discount / 100) * formik.values.msrp
        : (
            (1 + formik.values.markup / 100) * formik.values.amount -
            (formik.values.discount / 100) *
              (1 + formik.values.markup / 100) *
              formik.values.amount
          ).toFixed(2)
    );
  }, [
    formik.values.msrp,
    formik.values.discount,
    formik.values.markup,
    formik.values.amount,
  ]);

  useEffect(() => {
    setProfit(
      (((salesPrice - formik.values.amount) / salesPrice) * 100).toFixed(2)
    );
  }, [salesPrice, formik.values.amount]);

  if (contactsLoading) {
    return;
  }

  return (
    <>
      <Grid2 container spacing={1} xs={12}>
        <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={1}>
          {/* <Grid2 xs={12}> */}
          <Card elevation={4}>
            <CardHeader title={"Projected Expense"} />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                select
                fullWidth
                size="small"
                margin="dense"
                id="category"
                name="category"
                label="Category"
                //   helperText="Select Project Type"
                value={formik.values.category}
                // defaultValue={data.projectType}
                onChange={formik.handleChange}
                // onChange={onChangeLookUp}
                // onBlur={onChangeLookUp}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={formik.touched.category && formik.errors.category}
              >
                <MenuItem value="CC Fee">CC Fee</MenuItem>
                <MenuItem value="Eval Fee">Eval Fee</MenuItem>
                <MenuItem value="Shipping">Shipping</MenuItem>
                <MenuItem value="Labor">Labor</MenuItem>
                <MenuItem value="Materials">Materials</MenuItem>
              </TextField>

              <TextField //cost
                fullWidth
                // multiline
                // rows={3}
                size="small"
                margin="dense"
                id="amount"
                name="amount"
                label="Cost"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
              ></TextField>

              <TextField //markup
                fullWidth
                // multiline
                // rows={3}
                size="small"
                margin="dense"
                id="markup"
                name="markup"
                label="Markup"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                value={formik.values.markup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.markup && Boolean(formik.errors.markup)}
                helperText={formik.touched.markup && formik.errors.markup}
              ></TextField>

              <Typography>Or</Typography>

              <TextField //msrp
                fullWidth
                // multiline
                // rows={3}
                size="small"
                margin="dense"
                id="msrp"
                name="msrp"
                label="MSRP"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.msrp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.msrp && Boolean(formik.errors.msrp)}
                helperText={formik.touched.msrp && formik.errors.msrp}
              ></TextField>

              <TextField //msrp
                fullWidth
                // multiline
                // rows={3}
                size="small"
                margin="dense"
                id="discount"
                name="discount"
                label="Discount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                value={formik.values.discount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.discount && Boolean(formik.errors.discount)
                }
                helperText={formik.touched.discount && formik.errors.discount}
              ></TextField>

              <TextField //description
                fullWidth
                multiline
                rows={3}
                size="small"
                margin="dense"
                id="description"
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              ></TextField>

              <Typography>
                Calculated sale price:{" "}
                {formik.values.msrp
                  ? formik.values.msrp -
                    (formik.values.discount / 100) * formik.values.msrp
                  : (
                      (1 + formik.values.markup / 100) * formik.values.amount -
                      (formik.values.discount / 100) *
                        (1 + formik.values.markup / 100) *
                        formik.values.amount
                    ).toFixed(2)}
              </Typography>

              <Typography>state value of saleprice: {salesPrice}</Typography>

              <Typography>
                Profit%:{" "}
                {formik.values.msrp
                  ? (
                      (formik.values.msrp -
                        (formik.values.discount / 100) * formik.values.msrp -
                        formik.values.amount) /
                      (formik.values.msrp -
                        (formik.values.discount / 100) * formik.values.msrp)
                    ).toFixed(2) * 100
                  : 0}
              </Typography>

              <Typography>state value of profit: {profit}</Typography>

              <TextField //contact
                fullWidth
                select
                size="small"
                margin="dense"
                id="contact"
                name="contact"
                label="Contact"
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contact && Boolean(formik.errors.contact)}
                helperText={formik.touched.contact && formik.errors.contact}
              >
                {contacts
                  .filter((option) => {
                    return option.contactType === "sub contractor";
                  })
                  .map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.companyName}
                    </MenuItem>
                  ))}
              </TextField>

              <Button
                sx={{ borderRadius: "50px", m: 1 }}
                type="submit"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Enter Expense
              </Button>

              <Button
                // size="small"
                sx={{ borderRadius: "50px", m: 1 }}
                onClick={() => props.showSideForm(false)}
                // type="Enter"
                variant="contained"
                // disabled={!(formik.isValid && formik.dirty)}
              >
                Cancel
              </Button>
            </form>
          </Card>

          {/* </Grid2> */}
        </Box>
      </Grid2>
    </>
  );
};

export default ExpenseForm;

// export async function loader() {
//   return queryClient.fetchQuery({
//     queryKey: ["contacts"],
//     queryFn: ({ signal }) => fetchContacts({ signal }),
//   });
// }
