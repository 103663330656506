import React from "react";
import { redirect } from 'react-router-dom';


import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { useQuery } from "@tanstack/react-query";

import { fetchActiveProjects, queryClient } from "../tools/http";
import LongProjectTable from "../components/Project/LongProjectTable";
import { Typography } from "@mui/material";
import { getAuthToken } from "../tools/auth";

function Operations() {
  const { data, isError, isPending, isLoading, error } = useQuery({
    queryKey: ["projectlist"],
    queryFn: ({ signal }) => fetchActiveProjects({ signal }),
    staleTime: 10000,
  });
  // const newProjects = data.filter((project) => project.statusFlags.isAccepted === false);
  // const activeProjects = data.filter((project) => project.statusFlags.isAccepted === true);
if (isError){
  console.log(error)
}
  return (<>
  {(!isError && !isPending) ?  (
        <Grid2 spacing={2} p={2} container>
      <Grid2 xs={12}>
              <Typography variant="h4" color="primary">Operations</Typography>
      </Grid2>

      <LongProjectTable projects={data.filter((project) => project.statusFlags.isAccepted === false)} header="New Projects" />
      <LongProjectTable projects={data.filter((project) => project.statusFlags.isAccepted === true)} header="Active Projects" />

    </Grid2>
  )
   : (      <Grid2 xs={12}>
    <Typography variant="h4" color="primary">Operations</Typography>
</Grid2>)}
  </>

  );
}

export default Operations;

export async function loader({ params }) {

  const info = getAuthToken();
  
  if (!info) {
      console.log("no")
    return redirect('/auth');
    // return null;
  }else 
  return queryClient.fetchQuery({
    queryKey: ["projectlist"],
    queryFn: ({ signal }) => fetchActiveProjects({ signal }),
  });
}
