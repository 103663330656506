import React from "react";
import ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField, Card, CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import { useQuery, useMutation } from "@tanstack/react-query";

import { updateFinance, fetchContacts,updateProject, queryClient } from "../../tools/http";

const LayoutReview = (props) => {

    const { mutate: mutateProject } = useMutation({
        mutationFn: updateProject,
        onSuccess: () => {
          console.log("mutate success");
          queryClient.invalidateQueries({ queryKey: ["project"] });
        },
      });

      const reviewLayout = async () => {
        // console.log(props.data);
        const newData = {
          ...props.data,
          projectStatus: "Estimate",
          layout: {
            ...props.data.layout,
            reviewDate: new Date(),
          },
        };
        
        await mutateProject(newData);
        props.showSideForm(false)
      };

//   const data = props.data;
//   const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
    
    },
    validationSchema: Yup.object({
    }),

    onSubmit: (values) => {
    //   const update = {
    //     _id: data.finance._id,
    //     values: values,
    //   };
    //   mutateFinance(update);
      // console.log(update)
    //   console.log(values);
      // const newData={...data, ...values}
      // console.log(newData)
      // props.onChange(newData);
      // alert(JSON.stringify(values, null, 2));
      // props.onAddLead(values);
      formik.resetForm((values = ""));
      props.showSideForm(false);
    },
  });

//   if (contactsLoading) {
//     return;
//   }

  return (
    
      <Grid2 container spacing={1} xs={12}>
        <Grid2 xs={12}>
        <Box borderRadius={"15px"} boxShadow={3} padding={1} margin={0}>
          {/* <Grid2 xs={12}> */}
          <Card elevation={4}>
            <CardHeader title={"Layout Review"} />
            <form onSubmit={formik.handleSubmit}>
             <Typography>checklist
             </Typography>

              <Button
                sx={{ borderRadius: "50px", m: 1 }}
                onClick={reviewLayout}
                // type="submit"
                variant="contained"
                // disabled={!(formik.isValid && formik.dirty)}
              >
                Complete
              </Button>

              <Button
                // size="small"
                sx={{ borderRadius: "50px", m: 1 }}
                onClick={() => props.showSideForm(false)}
                // type="Enter"
                variant="contained"
                // disabled={!(formik.isValid && formik.dirty)}
              >
                Cancel
              </Button>
            </form>
          </Card>

          {/* </Grid2> */}
        </Box>
        </Grid2>
      </Grid2>
    
  );
};

export default LayoutReview;

